<template>
  <div class="contact-modal" @click.self="closeModal">
    <div class="contact-dialog">
      <button class="close-button" @click="closeModal">×</button>
      <form @submit.prevent="sendEmail" class="contact-form">
        <div class="form-group">
          <label for="name">Name:</label>
          <input
            v-model="formData.name"
            type="text"
            id="name"
            placeholder="Your Name"
            required
          />
        </div>
        <div class="form-group">
          <label for="email">Email:</label>
          <input
            v-model="formData.email"
            type="email"
            id="email"
            placeholder="Your Email"
            required
          />
        </div>
        <div class="form-group">
          <label for="message">Message:</label>
          <textarea
            v-model="formData.message"
            id="message"
            placeholder="Your Message"
            required
          ></textarea>
        </div>
        <button type="submit">Send</button>
        <p v-if="messageSent" class="success-message">
          Email sent successfully!
        </p>
        <p v-if="messageError" class="error-message">
          There was an error sending your email. Please try again.
        </p>
      </form>
      <div class="contact-info">
        <p class="info-item">
          <font-awesome-icon :icon="['fas', 'envelope']" />
          <a href="mailto:your-email@example.com">talsondavid@outlook.com</a>
        </p>
        <p class="info-item">
          <font-awesome-icon :icon="['fas', 'phone']" />
          <a href="tel:+1234567890">+1 (289) 237-0824</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits } from 'vue';
import emailjs from 'emailjs-com';

const emit = defineEmits(['close']);
const formData = ref({
  name: '',
  email: '',
  message: '',
});

const messageSent = ref(false);
const messageError = ref(false);

const sendEmail = () => {
  emailjs
    .send(
      'service_lj4ruki',
      'template_4v7lhon',
      {
        name: formData.value.name,
        email: formData.value.email,
        message: formData.value.message,
      },
      'XNU0XLqA70tZR29y1',
    )
    .then(
      (response) => {
        console.log('SUCCESS!', response.status, response.text);
        messageSent.value = true;
        messageError.value = false;
        formData.value = { name: '', email: '', message: '' }; // Clear form
        // Close the modal after 5 seconds
        setTimeout(() => {
          closeModal();
        }, 5000);
      },
      (error) => {
        console.log('FAILED...', error);
        messageSent.value = false;
        messageError.value = true;
      },
    );
};

const closeModal = () => {
  emit('close');
};
</script>

<style scoped>
.contact-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Higher z-index for the modal */
}

.contact-dialog {
  background: #ffffff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 600px;
  width: 90%;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #333;
  cursor: pointer;
}

.close-button:hover {
  color: #ff4d4d;
}

h2 {
  margin-top: 0;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-group {
  display: flex;
  flex-direction: column;
}

label {
  font-weight: bold;
}

input,
textarea {
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

textarea {
  resize: vertical;
  min-height: 100px;
}

button {
  padding: 0.3rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

button:hover {
  background-color: #0056b3;
}

.success-message {
  color: green;
  font-weight: bold;
}

.error-message {
  color: red;
  font-weight: bold;
}

.contact-info {
  margin-top: 1rem;
  text-align: center;
  font-size: 1rem;
  color: #333;
}

.contact-info a {
  color: #007bff;
  text-decoration: none;
}

.contact-info a:hover {
  text-decoration: underline;
}
.contact-info {
  display: flex;
  justify-content: space-around;
}
.info-item {
  gap: 0.25rem;
  display: flex;
  align-items: center;
}
</style>
