<template>
  <div class="project-container">
    <section class="hero">
      <h1 class="header">{{ project.title }}</h1>
      <div class="hero-content">
        <img
          :src="project.heroImage"
          alt="Project Hero Image"
          class="hero-image"
        />
      </div>
      <div class="project-details">
        <h1 class="header">Summary</h1>
        <p class="project-summary">{{ project.summary }}</p>
      </div>
    </section>

    <section class="project-info">
      <h3 class="header">Tools</h3>
      <ul class="project-tools">
        <li v-for="tool in project.tools" :key="tool">{{ tool }}</li>
      </ul>

      <h3 class="header">System Architecture</h3>
      <img
        :src="project.architectureDiagram"
        alt="System Architecture Diagram"
        class="architecture-diagram"
      />

      <h3 class="header">Demo</h3>
      <div class="demo" v-if="project.demoLink">
        <button @click="showVideoModal" class="demo-button">Click Me</button>
      </div>
      <div v-else>
        <p>No demo available</p>
      </div>
    </section>
    <VideoModal
      :isOpen="isVideoModalOpen"
      :videoSrc="project.demoLink"
      @close="isVideoModalOpen = false"
    />
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import VideoModal from '../components/VideoModal.vue';

const project = ref({
  title: 'Target Drone with Autonomous Landing',
  summary:
    "This project involves developing an autonomous drone system designed to search an area using a grid pattern, detect objects using an AI-based object detection model, estimate the real-world coordinates of detected objects, and autonomously land on the detected objects. The system consists of five main components: Drone Control and Stabilization for maintaining stable flight and accurate navigation, Drone Search for scanning areas iteratively, Object Detection for identifying objects in the drone's video feed, Position Estimation for determining the real-world coordinates of detected objects, and Drone Landing for safely navigating to and landing on the detected objects. The integration of these components is essential, involving real-time data sharing to ensure effective decision-making and seamless operation.",
  tools: ['Matlab', 'Simulink', 'QUARC', 'OptiTrack'],
  architectureDiagram: '/projects/target_drone/system_architecture.png',
  demoLink: '/projects/target_drone/demo.mp4',
  heroImage: '/projects/target_drone/hero.jpg',
});

const footerSection = ref(null);

const scrollToFooter = () => {
  footerSection.value.scrollIntoView({ behavior: 'smooth' });
};
const isVideoModalOpen = ref(false);

const showVideoModal = () => {
  isVideoModalOpen.value = true;
};

// Fetch project details based on route params
// onMounted(async () => {
//   const projectId = route.params.id;
//   try {
//     const response = await fetch(
//       `https://portfolio-backend-vbub.onrender.com/api/projects/${projectId}`,
//     );
//     if (!response.ok) {
//       throw new Error('Network response was not ok');
//     }
//     const data = await response.json();
//     project.value = data;
//   } catch (error) {
//     console.error('Error fetching project details:', error);
//   }
// });
</script>

<style scoped>
p {
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  color: #333;
  margin: 15px 0;
  padding: 10px 15px;
  background: linear-gradient(to right, #f0f0f0, #e0e0e0);
  border-left: 5px solid #cd7f32;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, background 0.3s ease;
  animation: fadeInUp 2s ease-out;
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
p:hover {
  transform: scale(1.02);
  background: linear-gradient(to right, #e0e0e0, #f0f0f0);
}
.project-container {
  font-family: Arial, sans-serif;
  color: #333;
  margin: 5rem auto;
  padding: 20px;
  width: 70%;
}

.hero {
  text-align: center;
  padding: 50px;
  display: block;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.hero-content {
  margin: 0 auto;
}

.hero-image {
  width: 25%;
  /* border-radius: 25%; */
  height: auto;
  box-shadow: 0 4px 8px rgba(0.5, 0.5, 0.5, 0.5);
  margin: auto;
}

.project-details {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  width: 100%;
  text-align: left;
  line-height: 30px;
  margin-top: 2rem;
}

.header {
  text-align: center;
  font-family: 'Comic Sans MS', 'Comic Sans', cursive;
  font-size: 2rem;
  color: #333;
  padding: 10px 0px;
  border-bottom: 2px solid #ccc;
  margin-bottom: 20px;
}

.project-info {
  padding: 20px;
}

.project-tools {
  list-style: none;
  padding: 0;
  margin: auto;
}

.project-tools li {
  background: #efefef;
  padding: 5px 10px;
  margin: 5px 0;
  display: inline-block;
  font-size: larger;
}

.architecture-diagram {
  max-width: 100%;
  height: auto;
  margin: 20px 0;
}
.demo {
  display: flex;
  justify-content: center;
  width: 100%;
}
.demo-button {
  background-color: #b08d57;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1.5em;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.demo-button:hover {
  background-color: #b08d57;
  transform: scale(1.05);
}

.demo-button:active {
  background-color: #b08d57;
  transform: scale(1);
}

@media (max-width: 1600px) {
  .project-container {
    margin: 2rem auto;
    padding: 20px;
    width: 90%;
  }
}

@media (max-width: 1250px) {
  .hero {
    text-align: center;
    padding: 50px;
    display: block;
    align-items: center;
    width: 100%;
    justify-content: center;
  }
  .hero-image {
    width: 25rem;
    border-radius: 25%;
    height: auto;
    box-shadow: 0 4px 8px rgba(0.5, 0.5, 0.5, 0.5);
    margin: auto;
  }

  .project-details {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    width: 100%;
    text-align: left;
    line-height: 30px;
  }
}

@media (max-width: 700px) {
  p {
    font-size: 1.25rem;
  }
}

@media (max-width: 500px) {
  .hero {
    text-align: center;
    padding: 20px 0px;
    display: block;
    align-items: center;
    width: 100%;
    justify-content: center;
  }
  .header {
    font-size: 1rem;
  }
  .hero-image {
    width: 10rem;
    border-radius: 25%;
    height: auto;
    box-shadow: 0 4px 8px rgba(0.5, 0.5, 0.5, 0.5);
    margin: auto;
  }
  .project-details {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    width: 100%;
    text-align: left;
    line-height: 30px;
  }
  p {
    font-size: 1rem;
  }
  .demo-button {
    font-size: 1rem;
  }
}
</style>
