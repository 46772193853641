<template>
  <footer class="home-footer" ref="footerSection">
    <div class="home-information">
      <div class="home-details">
        <div class="home-links2">
          <span class="social">2024, All rights Reserved</span>
        </div>
      </div>
    </div>
    <!-- <img alt="image" src="/footer-1400w.png" class="home-image1" /> -->
  </footer>
</template>

<script>
export default {
  name: 'Footer',
};
</script>
<style scoped>
.home-footer {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #2c2c2c; /* Darker background for contrast */
  color: #ffffff;
  padding: 5px;
  border-top: 3px solid #cd7f32; /* Bronze color for border */
  position: fixed;
  bottom: 0rem;
  left: 0;
}

.home-information {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.home-details {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  gap: 15px;
}

.home-header6 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.home-location,
.home-location1 {
  text-align: center;
  display: flex;
  align-items: center;
  gap: 10px;
}

.home-caption {
  font-size: 18px;
  font-weight: bold;
  color: #cd7f32;
  letter-spacing: 1px;
}

.home-value {
  font-size: 16px;
  font-weight: 300;
  color: #e0e0e0; /* Light gray color for values */
  margin-top: 5px;
}

.home-caption1 {
  font-size: 18px;
  font-weight: bold;
  color: #cd7f32; /* Bronze color for captions */
  letter-spacing: 1px;
}

.home-value1 {
  font-size: 16px;
  font-weight: 300;
  color: #e0e0e0; /* Light gray color for values */
  margin-top: 5px;
}

.home-links2 {
  display: flex;
  justify-content: center;
}

.social {
  font-size: 14px;
  font-weight: 300;
  color: #a9a9a9; /* Lighter gray color for footer text */
  text-align: center;
  margin: 0;
  padding: 10px;
  background: linear-gradient(to right, #2c2c2c, #1a1a1a);
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
@media (max-width: 500px) {
  .home-location,
  .home-location1 {
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
  }
  .scroll-indicator {
    position: fixed;
    bottom: 3rem;
    font-size: 2rem;
    width: 100%;
    display: flex;
    justify-content: right;
    padding-right: 1rem;
    color: #cd7f32;
    animation: moveUpDown 1.5s infinite;
  }
}
</style>
