import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
// import LottieVuePlayer from '@lottiefiles/vue-lottie-player';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faPen,
  faLocationPin,
  faEnvelope,
  faUser,
  faTrophy,
  faImage,
  faPrint,
  faMouse,
  faPhone,
  faBars,
} from '@fortawesome/free-solid-svg-icons';
import {
  faTwitter,
  faGithub,
  faLinkedin,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';

// Add FontAwesome icons to the library
library.add(
  faTwitter,
  faGithub,
  faLinkedin,
  faInstagram,
  faPen,
  faLocationPin,
  faEnvelope,
  faUser,
  faTrophy,
  faImage,
  faPrint,
  faMouse,
  faPhone,
  faBars,
);

// Create the Vue app
const app = createApp(App);

// Use the LottieVuePlayer plugin
// app.use(LottieVuePlayer);

// Register FontAwesomeIcon component globally
app.component('font-awesome-icon', FontAwesomeIcon);

// Use the router
app.use(router);

// Mount the app
app.mount('#app');
