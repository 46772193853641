// src/router.js
import { createRouter, createWebHistory } from 'vue-router';
import Home from './views/home.vue';
import ProjectDetails from './views/projectDetails.vue';
import NotFound from './views/not-found.vue';
import About from './views/About.vue';
import Portfolio from './views/Portfolio.vue';
import './style.css';

const routes = [
  {
    name: 'Home',
    path: '/',
    component: Home,
  },
  {
    name: '404 - Not Found',
    path: '/:pathMatch(.*)*', // This is the new way to handle 404 in Vue Router 4
    component: NotFound,
  },
  {
    path: '/project/:id',
    name: 'ProjectDetails',
    component: ProjectDetails,
  },
  {
    path: '/about',
    name: 'About',
    component: About, // Add the route for the About component
  },
  {
    path: '/portfolio',
    name: 'Portfolio',
    component: Portfolio, // Add the route for the About component
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
