<template>
  <div class="home-navbar">
    <header data-thq="thq-navbar" class="home-navbar-interactive">
      <div class="home-branding">
        <video
          class="home-logo"
          src="/logo.mp4"
          type="video/mp4"
          autoplay
          loop
        ></video>
      </div>
      <div class="home-items">
        <div class="home-links">
          <li class="nav-link">
            <button class="nav-button" @click="navigateHome">Home</button>
          </li>
          <li class="nav-link">
            <button class="nav-button" @click="goToPortfolio">Portfolio</button>
          </li>
          <li class="nav-link">
            <button class="nav-button" @click="goToBlog">Blog</button>
          </li>
          <li class="nav-link">
            <button class="nav-button" @click="goToAbout">About</button>
          </li>
          <li class="nav-link">
            <button class="nav-button" @click="showContactForm = true">
              Contact
            </button>
          </li>
        </div>
      </div>
      <div class="home-burger-menu">
        <button class="home-button1" @click="toggleMobileMenu">
          <font-awesome-icon icon="fa-bars" class="icon" />
        </button>
      </div>
      <div
        v-if="isMobileMenuOpen"
        data-thq="thq-mobile-menu"
        class="home-mobile-menu"
      >
        <div data-thq="thq-mobile-menu-nav" data-role="Nav" class="home-nav">
          <div class="home-menu-close" @click="toggleMobileMenu">
            <svg viewBox="0 0 1024 1024" class="home-icon">
              <path
                d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"
              ></path>
            </svg>
          </div>
          <div class="home-items1">
            <div class="home-links1">
              <li class="nav-link">
                <button class="nav-button" @click="navigateHome">Home</button>
              </li>
              <li class="nav-link">
                <button class="nav-button" @click="goToPortfolio">
                  Portfolio
                </button>
              </li>
              <li class="nav-link">
                <button class="nav-button" @click="goToBlog">Blog</button>
              </li>
              <li class="nav-link">
                <button class="nav-button" @click="goToAbout">About</button>
              </li>
              <li class="nav-link">
                <button
                  class="nav-button"
                  @click.prevent="showContactForm = true"
                >
                  Contact
                </button>
              </li>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div
      v-if="showContactForm"
      class="contact-modal"
      @click.self="showContactForm = false"
    >
      <div class="contact-dialog">
        <Contact v-if="showContactForm" @close="showContactForm = false" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, nextTick } from 'vue';
import { useRouter } from 'vue-router';
import Contact from '../components/Contact.vue';

export default {
  name: 'Navbar',
  components: {
    Contact,
  },
  setup() {
    const router = useRouter();
    const showContactForm = ref(false);
    const isMobileMenuOpen = ref(false);
    const toggleMobileMenu = () => {
      isMobileMenuOpen.value = !isMobileMenuOpen.value;
    };
    const navigateHome = async () => {
      try {
        await router.push('/');
        await nextTick();
        const home = document.querySelector('.home-container');
        if (home) {
          home.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        isMobileMenuOpen.value = false;
      } catch (error) {
        console.error('Error navigating to home:', error);
      }
    };
    const goToAbout = () => {
      router.push({ name: 'About' });
      isMobileMenuOpen.value = false;
    };
    const scrollToFooter = () => {
      const footer = document.querySelector('.home-footer');
      if (footer) {
        footer.scrollIntoView({ behavior: 'smooth' });
      }
    };
    const goToPortfolio = () => {
      router.push({ name: 'Portfolio' });
      isMobileMenuOpen.value = false;
    };
    const goToBlog = () => {
      window.open(
        'https://davidtalson.medium.com/',
        '_blank',
        'noopener noreferrer',
      );
    };

    return {
      isMobileMenuOpen,
      toggleMobileMenu,
      scrollToFooter,
      goToAbout,
      navigateHome,
      goToPortfolio,
      showContactForm,
      goToBlog,
    };
  },
};
</script>

<style scoped>
@keyframes moveUpDown {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}
.nav-link {
  padding: 1px;
  border-radius: 8px;
  color: white;
  list-style-type: none;
}
.nav-button {
  color: #f5f5f5;
  border: none;
  border-bottom: 3px solid #b08d57;
  background-color: transparent;
  font-size: 18px;
  font-weight: 700;
  padding: 5px 10px;
  margin: 5px;
  cursor: pointer;
  letter-spacing: 1px;
  font-family: 'Comic Sans MS', 'Comic Sans';
  transition: all 0.3s ease;
}

.nav-button:hover {
  background-color: #444;
  color: #ffffff;
  border-color: #8c5e4e;
}
.nav-button:active {
  transform: translateY(1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.home-navbar {
  width: 100vw;
  z-index: 100;

  background-color: rgb(51, 51, 51);
  position: fixed;
  top: 0;
  left: 0;
}
.home-navbar-interactive {
  max-width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  background-color: transparent;
  margin: auto;
  padding: 0px 1rem;
}

@keyframes slide-background {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 0;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.home-branding {
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 50%;
  height: 100%;
}
.home-me {
  color: #ffffff;
  font-size: 25px;
  font-weight: 300;
}
.home-logo {
  width: 50px;
  height: 50px;
  object-fit: cover;
  transition: 0.3s;
  animation: scale 5s infinite;
  border-radius: 35%;
}
@keyframes scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.home-company {
  color: rgb(255, 255, 255);
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
}
.home-items {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: right;
  flex-direction: row;
  width: 50%;
}
.home-links {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: right;
  flex-direction: row;
  justify-content: right;
  width: 100%;
}

.home-burger-menu {
  display: none;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}
.home-button1 {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  padding-top: 20px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border: none;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: 20px;
  animation: moveUpDown 5s infinite;
  background-color: transparent;
}
.home-image {
  width: 100px;
  display: none;
  object-fit: cover;
}
.home-text05 {
  color: #ffffff;
  display: flex;
}
.home-mobile-menu {
  top: 0%;
  left: 50%;
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 32px;
  z-index: 10000;
  position: fixed;
  flex-direction: column;
  justify-content: space-between;
  background-color: #151515;
}
.home-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-logo1 {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.home-menu-close {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-icon {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
}
.home-items1 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-links1 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.icon {
  color: #ffffff;
}

@media (max-width: 1500px) {
  .home-navbar-interactive {
    padding-top: var(--dl-space-space-unit);
  }
  .home-button {
    width: 100%;
    font-size: 16px;
    text-align: center;
    border-radius: 40px;
  }

  .home-button1 {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-image {
    width: 18px;
    height: 18px;
    display: flex;
  }

  .home-link {
    display: none;
  }
}
@media (max-width: 900px) {
  .home-navbar-interactive {
    padding-top: var(--dl-space-space-oneandhalfunits);
  }
  .home-logo {
    width: 36px;
    height: 36px;
  }
  .home-company {
    font-size: 18px;
    line-height: 20px;
  }
  .home-items {
    display: none;
  }
  .home-burger-menu {
    display: flex;
  }
  .home-button1 {
    padding: 12px;
  }
  .home-image {
    width: 14px;
    height: 14px;
  }
  .home-header {
    padding-bottom: var(--dl-space-space-fiveunits);
  }
}
@media (max-width: 1000px) {
  .logos {
    /* display: block;
    align-content: center;
    justify-content: center; */
  }
  .credentials {
    display: block;
  }
}
@media (max-width: 479px) {
  .home-mobile-menu {
    padding: 16px;
  }
}
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s;
}
.slide-enter,
.slide-leave-to {
  transform: translateX(0);
}
.slide-enter-to,
.slide-leave {
  transform: translateX(100%);
}
</style>
