<template>
  <div class="home-container" @mousemove="handleMouseMove">
    <div class="home-layout">
      <button class="hire-me" @click.prevent="showContactForm = true">
        Let's Discuss
      </button>
      <div class="home-header">
        <div class="container">
          <h1 class="home-title" data-text="DAVID TALSON">DAVID TALSON</h1>
        </div>
        <div class="home-text">
          <h4 class="home-summary">
            <span class="icon-container">
              <font-awesome-icon :icon="['fas', 'print']" class="print-icon" />
              <span ref="typingText" class="display-text">{{
                displayedText
              }}</span>
            </span>
          </h4>
          <div class="social-links">
            <a
              href="https://github.com/dtee1"
              target="_blank"
              rel="noopener noreferrer"
            >
              <font-awesome-icon class="icon" :icon="['fab', 'github']" />
            </a>
            <a
              href="https://www.linkedin.com/in/david-talson-718834132/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <font-awesome-icon class="icon" :icon="['fab', 'linkedin']" />
            </a>
            <a
              href="https://davidtalson.medium.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <font-awesome-icon class="icon" :icon="['fas', 'pen']" />
            </a>
          </div>
          <div class="home-resume">
            Click
            <a
              class="resume-download"
              href="/David_Talson_Resume_Engineer.pdf"
              download
              >here</a
            >
            to download my resume
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="showContactForm"
      class="contact-modal"
      @click.self="showContactForm = false"
    >
      <div class="contact-dialog">
        <Contact v-if="showContactForm" @close="showContactForm = false" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import Contact from '../components/Contact.vue';

const texts = [
  'Software Engineer ',
  "UOttawa '24, Master's",
  'Based in Canada',
];

const displayedText = ref('');
const textIndex = ref(0);
const typingSpeed = 50;
const typingDuration = 1000;
const showContactForm = ref(false);
const typeText = () => {
  const text = texts[textIndex.value];
  let charIndex = 0;
  let isDeleting = false;

  displayedText.value = '|';

  const typingInterval = setInterval(() => {
    if (!isDeleting) {
      displayedText.value = text.substring(0, charIndex + 1) + '|';
      charIndex += 1;

      if (charIndex >= text.length) {
        clearInterval(typingInterval);
        setTimeout(() => {
          isDeleting = true;
          charIndex = text.length - 1;
          const deletingInterval = setInterval(() => {
            displayedText.value = text.substring(0, charIndex) + '|';
            charIndex -= 1;

            if (charIndex < 0) {
              clearInterval(deletingInterval);
              setTimeout(() => {
                textIndex.value = (textIndex.value + 1) % texts.length;
                displayedText.value = '|';
                typeText();
              }, typingDuration);
            }
          }, typingSpeed);
        }, typingDuration);
      }
    }
  }, typingSpeed);
};
const handleMouseMove = (event) => {
  const container = event.currentTarget.querySelector('.home-layout');
  const { clientX: mouseX, clientY: mouseY } = event;
  const { offsetWidth: width, offsetHeight: height } = container;

  const xPercent = (mouseX / width - 0.5) * 5;
  const yPercent = (mouseY / height - 0.5) * 5;

  container.style.backgroundPosition = `${50 + xPercent}% ${50 + yPercent}%`;
};

onMounted(() => {
  typeText();
});
</script>

<style scoped>
@keyframes moveUpDown {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-50px);
  }
}
.home-container {
  width: 90%;
  height: 100vh;
  margin: auto;
  display: flex;
  align-content: center;
  align-items: center;
  z-index: -1;
  transition: transform 0.4s ease, box-shadow 0.4s ease;
  animation: moveUpDown 2.5s ease-in-out;
}

.home-layout {
  display: flex;
  flex-direction: column;
  font-family: 'Arial', sans-serif;
  font-size: 1.2rem;
  color: rgb(51, 51, 51);
  width: 100%;
  height: 80%;
  background-image: url('/background.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  justify-content: center;
  margin: auto;
  border-radius: 1rem;
  transition: background-position 0.1s ease;
}

.hire-me {
  background-color: rgb(205, 127, 50);
  color: white;
  width: 15rem;
  text-align: center;
  margin: 1rem auto;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 0.4rem;
  font-size: 1rem;
  font-weight: 600;
  font-family: 'Arial', sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.hire-me:hover {
  background-color: rgb(179, 105, 39);
  transform: scale(1.05);
}

.hire-me:focus {
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(255, 105, 0, 0.5);
}

.home-header {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.home-title {
  position: relative;
  font-family: 'Comic Sans MS', 'Comic Sans';
  font-weight: 100;
  letter-spacing: 0.5rem;
  font-size: 6.25rem;
  color: rgb(255, 255, 255);
  background-color: rgb(255, 255, 255);
  width: 100%;
  z-index: 1;
}

.home-title::before,
.home-title::after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.8;
  pointer-events: none;
}

.home-title::before {
  animation: glitch-before 10s infinite;
  clip-path: polygon(0 0, 100% 0, 100% 45%, 0 45%);
  transform: translate(-0.025em, -0.0125em);
}

.home-title::after {
  animation: glitch-after 10s infinite;
  clip-path: polygon(0 65%, 100% 20%, 100% 100%, 0 70%);
  transform: translate(0.0125em, 0.025em);
}

@keyframes glitch-before {
  0%,
  100% {
    color: rgba(255, 255, 255, 0.8);
    text-shadow: 0.05em 0 0 rgba(0, 0, 0, 0.8),
      -0.025em -0.05em 0 rgba(0, 0, 0, 0.8), 0.025em 0.05em 0 rgba(0, 0, 0, 0.8);
  }
  14%,
  49% {
    text-shadow: 0.05em 0 0 rgba(0, 0, 0, 0.8),
      -0.025em -0.05em 0 rgba(0, 0, 0, 0.8), 0.025em 0.05em 0 rgba(0, 0, 0, 0.8);
  }
  15%,
  50% {
    color: rgba(0, 0, 0, 0.7);
    text-shadow: -0.05em -0.025em 0 rgba(255, 255, 255, 0.8),
      0.025em -0.025em 0 rgba(0, 0, 0, 0.8),
      -0.05em -0.05em 0 rgba(255, 255, 255, 0.8);
  }
  99% {
    color: rgba(0, 0, 0, 0.9);
    text-shadow: 0.025em 0.05em 0 rgba(255, 255, 255, 0.8),
      -0.025em 0.05em 0 rgba(0, 0, 0, 0.8), 0 -0.05em 0 rgba(255, 255, 255, 0.8);
  }
}

@keyframes glitch-after {
  0%,
  100% {
    color: rgba(255, 255, 255, 0.8);
    text-shadow: 0.05em 0 0 rgba(0, 0, 0, 0.8),
      -0.025em -0.05em 0 rgba(0, 0, 0, 0.8), 0.025em 0.05em 0 rgba(0, 0, 0, 0.8);
  }
  14%,
  49% {
    text-shadow: 0.05em 0 0 rgba(0, 0, 0, 0.8),
      -0.025em -0.05em 0 rgba(0, 0, 0, 0.8), 0.025em 0.05em 0 rgba(0, 0, 0, 0.8);
  }
  15%,
  50% {
    color: rgba(0, 0, 0, 0.7);
    text-shadow: -0.05em -0.025em 0 rgba(255, 255, 255, 0.8),
      0.025em -0.025em 0 rgba(0, 0, 0, 0.8),
      -0.05em -0.05em 0 rgba(255, 255, 255, 0.8);
  }
  99% {
    color: rgba(0, 0, 0, 0.9);
    text-shadow: 0.025em 0.05em 0 rgba(255, 255, 255, 0.8),
      -0.025em 0.05em 0 rgba(0, 0, 0, 0.8), 0 -0.05em 0 rgba(255, 255, 255, 0.8);
  }
}

.home-text {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 1.25rem;
}

.home-summary {
  font-size: 1rem;
  font-weight: 300;
  width: fit-content;
  line-height: 1.6;
  color: rgb(74, 74, 74);
  background-color: rgb(255, 255, 255);
  width: fit-content;
  text-align: center;
  margin: auto;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
}

.home-summary::after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 0.15em;
  animation: blink-caret 0.75s step-end infinite;
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: rgb(74, 74, 74);
  }
}

.social-links {
  gap: 1.25rem;
  display: flex;
  font-size: 1.5625rem;
  margin: auto;
  background-color: rgb(255, 255, 255);
  padding: 0.5rem;
  box-shadow: 0 0 0 0.2rem rgba(255, 105, 0, 0.5);
}
.icon {
  color: rgb(74, 74, 74);
}

.print-icon {
  margin-right: 0.625rem;
}

.home-resume {
  width: 100%;
  text-align: center;
  background-color: rgb(255, 255, 255);
  width: fit-content;
  margin: auto;
  padding: 0.5rem;
}

.resume-download {
  background-color: rgb(46, 46, 46);
  color: white;
  border-radius: 0.3125rem;
  padding: 0.3125rem 0.625rem;
  font-size: 1rem;
  border: none;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
}

@media (max-width: 1500px) {
  .home-layout {
    flex-direction: column;
    height: 70vh;
  }
  .home-title {
    font-size: 2.5rem;
    line-height: 2.25rem;
  }
}

@media (max-width: 600px) {
  .home-container {
    width: 100vw;
    height: 100vh;
    padding: 2rem;
    margin: auto;
    display: flex;
    align-content: center;
    align-items: center;
    z-index: -1;
    padding: 0;
    margin: 0;
  }
  .home-layout {
    justify-content: space-around;
    gap: 1rem;
    height: 70vh;
    width: 90%;
  }
  .home-title {
    font-size: 2rem;
    line-height: 2.5rem;
    font-weight: 900;
    width: 100%;
    margin: auto;
    text-align: center;
  }
  .home-summary {
    font-size: 1rem;
    font-weight: 400;
    line-height: 2;
    white-space: normal;
    overflow: auto;
  }
  .resume-download {
    padding: 0.3125rem 0.3125rem;
    font-size: 1rem;
  }
  .home-header {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
  }
  .hire-me {
    background-color: rgb(205, 127, 50);
    color: white;
    width: 10rem;
    text-align: center;
    margin: 1rem auto;
    padding: 0.5rem 0.75rem;
    border: none;
    border-radius: 0.4rem;
    font-size: 1rem;
    font-weight: 600;
    font-family: 'Arial', sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
}
</style>
