<template>
  <div class="about">
    <!-- Hero Section -->
    <section class="hero">
      <div class="hero-content">
        <img :src="heroImage" alt="Hero Image" class="hero-image" />
      </div>
      <div class="about-me">
        <h2 class="header">
          <font-awesome-icon icon="user" class="nav-icon" />About Me
        </h2>
        <p>{{ lifeSummary }}</p>
      </div>
    </section>

    <!-- Life Summary Section -->
    <!-- <section class="life-summary"></section> -->
    <section class="awards">
      <h2 class="header">
        <font-awesome-icon icon="trophy" class="nav-icon" />Achievements
      </h2>
      <div class="achievements-grid">
        <div v-for="(award, index) in awards" :key="index" class="award-item">
          <p>{{ award.title }} - {{ award.date }}: {{ award.institution }}</p>
          <p>{{ award.description }}</p>
        </div>
      </div>
    </section>

    <!-- Gallery Section -->
    <section class="gallery">
      <h2 class="header">
        <font-awesome-icon icon="image" class="nav-icon" />Gallery
      </h2>
      <div class="gallery-grid">
        <div
          v-for="(image, index) in galleryImages"
          :key="index"
          class="gallery-item"
        >
          <p class="gallery-title">{{ image.title }}</p>
          <img
            :src="image.src"
            :alt="image.title"
            class="gallery-image"
            @click="openModal(index)"
          />
        </div>
      </div>
    </section>
    <!-- Modal Section -->
    <div v-if="isModalOpen" class="modal" @click="closeModal">
      <span class="close" @click="closeModal">&times;</span>
      <img
        class="modal-content"
        :src="galleryImages[currentImageIndex].src"
        alt="Expanded Image"
      />
    </div>

    <!-- Awards Section -->
  </div>
</template>

<script setup>
import { ref, defineProps } from 'vue';

const props = defineProps({
  heroImage: {
    type: String,
    default: '/david-about.jpg',
  },
  lifeSummary: {
    type: String,
    default: `I'm David Talson, a dedicated Software Engineer with a Master's degree in Applied Artificial Intelligence from the University of Ottawa. With a robust background in both backend and frontend development, I've honed my skills in designing and implementing complex systems, optimizing application performance, and rigorous testing practices. My technical proficiency spans a variety of programming languages and frameworks, including HTML, CSS, Python, C++, MATLAB, JavaScript, Vue.js, and Node.js, among others.
                In my current role at Pleora Technologies, I have spearheaded several key projects, such as developing a comprehensive system monitoring feature for an AI web application, and refactoring components to enhance maintainability and scalability. My work has consistently improved the reliability and efficiency of the applications I manage.
                Beyond my professional pursuits, I am passionate about soccer and basketball, finding balance and inspiration in both team dynamics and the strategic aspects of these sports.`,
  },
  galleryImages: {
    type: Array,
    default: () => [
      { src: '/gallery/gallery-1.jpg', title: 'HS Graduation - 2014' },
      {
        src: '/gallery/gallery-2.jpg',
        title: 'Arrival to Canada - 2015',
      },
      {
        src: '/gallery/gallery-3.jpg',
        title: 'Carleton University - 2015',
      },
      { src: '/gallery/gallery-4.jpg', title: 'Carleton University - 2017' },
      { src: '/gallery/gallery-5.jpg', title: 'Tobermory - 2020' },
      { src: '/gallery/gallery-6.jpg', title: 'Tobermory - 2020' },
      { src: '/gallery/gallery-7.jpg', title: 'Tech Conference' },
      { src: '/gallery/gallery-8.jpg', title: 'Kingston - 2020' },
      { src: '/gallery/gallery-9.jpg', title: 'Kingston - 2020' },
      { src: '/gallery/gallery-10.jpg', title: 'Niagara - 2022' },
      { src: '/gallery/gallery-11.jpg', title: 'Ottawa - 2022' },
      { src: '/gallery/gallery-12.jpg', title: 'BENG Graduation - 2020' },
      { src: '/gallery/gallery-13.jpg', title: 'BENG Graduation - 2020' },
      { src: '/gallery/gallery-14.jpg', title: 'BENG Graduation - 2020' },
      { src: '/gallery/gallery-15.jpg', title: 'Camp Fortune - 2022' },
      { src: '/gallery/gallery-16.jpg', title: 'Vancouver - 2023' },
      { src: '/gallery/gallery-17.jpg', title: 'MENG Graduation- 2024' },
      { src: '/gallery/gallery-18.jpg', title: 'New Brunswick - 2023' },
      { src: '/gallery/gallery-19.jpg', title: 'Nova Scotia - 2023' },
      { src: '/gallery/gallery-20.jpg', title: 'Super League - 2023' },
    ],
  },
  awards: {
    type: Array,
    default: () => [
      {
        title: "Master's Degree",
        date: 'June 2024',
        institution: 'University of Ottawa',
        description: "Achieved a Master's Degree in Computer Science.",
      },
      {
        title: 'Rock-it Award',
        date: 'Sep 2023',
        institution: 'Pleora Technologies',
        description:
          'Received the Rock-it Award for outstanding performance in project management.',
      },
      {
        title: 'Rock-it Award',
        date: 'March 2022',
        institution: 'Pleora Technologies',
        description:
          'Awarded the Rock-it Award for exceptional innovation in product development.',
      },
      {
        title: 'Rock-it Award',
        date: 'Feb 2022',
        institution: 'Pleora Technologies',
        description:
          'Recognized with the Rock-it Award for excellence in customer support.',
      },
      {
        title: 'Rock-it Award',
        date: 'Sep 2021',
        institution: 'Pleora Technologies',
        description:
          'Earned the Rock-it Award for contributions to team leadership.',
      },
    ],
  },
});

const currentImageIndex = ref(0);

const isModalOpen = ref(false);

const openModal = (index) => {
  currentImageIndex.value = index;
  isModalOpen.value = true;
};

const closeModal = () => {
  isModalOpen.value = false;
};
</script>

<style scoped>
p {
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  color: #333;
  margin: 15px 0;
  padding: 10px 15px;
  background: linear-gradient(to right, #f0f0f0, #e0e0e0);
  border-left: 5px solid #cd7f32;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, background 0.3s ease;
  animation: fadeInUp 2s ease-out;
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
p:hover {
  transform: scale(1.02);
  background: linear-gradient(to right, #e0e0e0, #f0f0f0);
}
.about {
  font-family: Arial, sans-serif;
  margin: 5rem auto;
  padding: 20px;
  width: 90%;
  border-radius: 1rem;
  background: #ffffff;
}
.about-me {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  width: 60%;
  text-align: left;
  line-height: 30px;
}
.nav-icon {
  margin-right: 10px;
}
.header {
  text-align: center;
  font-family: 'Comic Sans MS', 'Comic Sans', cursive;
  font-size: 2rem;
  color: #333;
  padding: 10px 0px;
  border-bottom: 2px solid #ccc;
  margin-bottom: 20px;
}
.achievements {
  width: 30%;
}
.achievements-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: left;
}
.award-item {
  margin-bottom: 20px;
}
.hero {
  text-align: center;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.hero-content {
  margin: 0 auto;
}

.hero-image {
  width: 20rem;
  border-radius: 25%;
  height: auto;
  box-shadow: 0 4px 8px rgba(0.5, 0.5, 0.5, 0.5);
}

.life-summary {
  padding: 20px;
}

.gallery {
  padding: 20px;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0px;
}

.gallery-image {
  width: 100%;
  height: 300px;
  object-fit: fil;
  cursor: pointer;
}
.gallery-title {
  text-align: center;
  height: 4rem;
  align-content: center;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
}

.modal-content {
  max-width: 90%;
  max-height: 90%;
}

.close {
  position: absolute;
  top: 20px;
  right: 30px;
  color: white;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
}

.awards {
  padding: 20px;
}
.achievements-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}
.award-item {
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 5px;
  text-align: left;
}
.awards ul {
  list-style-type: none;
  padding: 0;
}
.awards li {
  padding: 5px 0;
}
@media (max-width: 1900px) {
  .gallery-grid {
    grid-template-columns: repeat(3, 1fr); /* 3 images per row */
  }
}
@media (max-width: 1600px) {
  .about {
    font-family: Arial, sans-serif;
    margin: 2rem auto;
    padding: 20px;
    width: 90%;
  }
}
@media (max-width: 1250px) {
  .hero {
    text-align: center;
    padding: 50px;
    display: block;
    align-items: center;
    width: 100%;
    justify-content: center;
  }
  .hero-image {
    width: 25rem;
    border-radius: 25%;
    height: auto;
    box-shadow: 0 4px 8px rgba(0.5, 0.5, 0.5, 0.5);
    margin: auto;
  }

  .about-me {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    width: 100%;
    text-align: left;
    line-height: 30px;
  }
}
@media (max-width: 1000px) {
  .gallery-grid {
    grid-template-columns: repeat(2, 1fr); /* 3 images per row */
  }
  .achievements-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
}
@media (max-width: 700px) {
  p {
    font-size: 1.25rem;
  }
}
@media (max-width: 500px) {
  .hero {
    text-align: center;
    /* padding: 50px; */
    padding: 20px 0px;
    display: block;
    align-items: center;
    width: 100%;
    justify-content: center;
  }
  .hero-image {
    width: 10rem;
    border-radius: 25%;
    height: auto;
    box-shadow: 0 4px 8px rgba(0.5, 0.5, 0.5, 0.5);
    margin: auto;
  }
  .about-me {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    width: 100%;
    text-align: left;
    line-height: 30px;
  }
  .awards {
    padding: 10px 0px;
  }
  .achievements-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }
  .gallery-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 0px;
  }
  p {
    font-size: 1rem;
  }
}
</style>
