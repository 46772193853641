<template>
  <div class="projects-container">
    <div class="projects-content">
      <h2 class="projects-title">Portfolio</h2>
      <div class="projects-grid">
        <div v-for="project in projects" :key="project.id" class="project-card">
          <img
            :src="project.image"
            :alt="project.title"
            class="project-image"
          />
          <h3 class="project-title">{{ project.title }}</h3>
          <a :href="project.link" target="_blank" class="project-link"
            >View Project</a
          >
        </div>
      </div>
    </div>
  </div>
  <div class="scroll-indicator">
    <font-awesome-icon icon="mouse" />
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';

const projects = ref([
  {
    id: 1,
    image: 'https://via.placeholder.com/150',
    title: 'Project 1',
    link: 'https://project1.com',
  },
  {
    id: 2,
    image: 'https://via.placeholder.com/150',
    title: 'Project 2',
    link: 'https://project2.com',
  },
  {
    id: 3,
    image: 'https://via.placeholder.com/150',
    title: 'Project 3',
    link: 'https://project3.com',
  },
  {
    id: 4,
    image: 'https://via.placeholder.com/150',
    title: 'Project 4',
    link: 'https://project4.com',
  },
  {
    id: 5,
    image: 'https://via.placeholder.com/150',
    title: 'Project 5',
    link: 'https://project5.com',
  },
  {
    id: 6,
    image: 'https://via.placeholder.com/150',
    title: 'Project 6',
    link: 'https://project6.com',
  },
  {
    id: 7,
    image: 'https://via.placeholder.com/150',
    title: 'Project 7',
    link: 'https://project7.com',
  },
  {
    id: 8,
    image: 'https://via.placeholder.com/150',
    title: 'Project 8',
    link: 'https://project8.com',
  },
  {
    id: 9,
    image: 'https://via.placeholder.com/150',
    title: 'Project 9',
    link: 'https://project9.com',
  },
]);
</script>

<style scoped>
@keyframes fadeInUp {
  from {
    transform: rotateY(180deg);
    opacity: 0;
  }
  to {
    transform: rotateY(0);
    opacity: 1;
  }
}
@keyframes moveUpDown {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.projects-container {
  width: 100%;
  height: 100vh;

  display: flex;
  align-items: center;
  flex-direction: column;
  background: #2c2c2c;
}
.projects-content {
  height: 80%;
  width: 100%;
  margin: auto;
}

.projects-title {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: #cd7f32;
  margin-top: 3rem;
  font-weight: bold;
  letter-spacing: 1.5px;
}

.projects-grid {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(3, 1fr);
  width: fit-content;
  align-content: center;
  align-items: center;
  margin: auto;
}

.project-card {
  background: #fff;
  border-radius: 10px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.4s ease, box-shadow 0.4s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 20rem;
  animation: fadeInUp 2.5s ease-in-out;
}

.project-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.project-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 1rem;
}

.project-title {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #333;
  text-align: center;
  font-weight: 600;
}

.project-link {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.project-link:hover {
  text-decoration: underline;
  color: #0056b3;
}
.scroll-indicator {
  position: fixed;
  bottom: 5rem;
  font-size: 2rem;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-right: 15rem;
  color: #cd7f32;
  animation: moveUpDown 1.5s infinite;
}
@media (max-width: 1300px) {
  .projects-grid {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    align-content: center;
    align-items: center;
    justify-content: center;
    margin: auto;
  }
  .project-card {
    background: #fff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.4s ease, box-shadow 0.4s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 70%;
    animation: fadeInUp 2.5s ease-in-out;
    margin: auto;
  }
}
@media (max-width: 500px) {
  .projects-grid {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
    align-content: center;
    align-items: center;
  }
  .project-card {
    background: #fff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.4s ease, box-shadow 0.4s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 20rem;
    animation: fadeInUp 2.5s ease-in-out;
  }
  .scroll-indicator {
    position: fixed;
    bottom: 7rem;
    font-size: 2rem;
    width: 100%;
    display: flex;
    justify-content: right;
    padding-right: 1rem;
    color: #cd7f32;
    animation: moveUpDown 1.5s infinite;
  }
}
@media (max-width: 400px) {
  .project-card {
    background: #fff;
    border-radius: 10px;
    /* padding: 1.5rem 0px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.4s ease, box-shadow 0.4s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 80%;
    animation: fadeInUp 2.5s ease-in-out;
    margin: auto;
  }
}
</style>
